import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Create incidental details:
export const CREATE_INCIDENT_DETAILS_REQUEST = "CREATE_INCIDENT_DETAILS_REQUEST";
export const CREATE_INCIDENT_DETAILS_SUCCESS = "CREATE_INCIDENT_DETAILS_SUCCESS";
export const CREATE_INCIDENT_DETAILS_FAILURE = "CREATE_INCIDENT_DETAILS_FAILURE";

// Create Perprator Detaials:
export const CREATE_PERPRATOR_DETAILS_REQUEST = "CREATE_PERPRATOR_DETAILS_REQUEST";
export const CREATE_PERPRATOR_DETAILS_SUCCESS = "CREATE_PERPRATOR_DETAILS_SUCCESS";
export const CREATE_PERPRATOR_DETAILS_FAILURE = "CREATE_PERPRATOR_DETAILS_FAILURE";

// Create witness Details:
export const CREATE_WITNESS_DETAILS_REQUEST = "CREATE_WITNESS_DETAILS_REQUEST";
export const CREATE_WITNESS_DETAILS_SUCCESS = "CREATE_WITNESS_DETAILS_SUCCESS";
export const CREATE_WITNESS_DETAILS_FAILURE = "CREATE_WITNESS_DETAILS_FAILURE";

//Get all Resons of abuse
export const GET_ALL_ABUSE_TYPES_REQUEST = "GET_ALL_ABUSE_TYPES_REQUEST";
export const GET_ALL_ABUSE_TYPES_SUCCESS = "GET_ALL_ABUSE_TYPES_SUCCESS";
export const GET_ALL_ABUSE_TYPES_FAILURE = "GET_ALL_ABUSE_TYPES_FAILURE";

export const GET_REPORT_ABUSE_DETAILS_REQUEST = "GET_REPORT_ABUSE_DETAILS_REQUEST";
export const GET_REPORT_ABUSE_DETAILS_SUCCESS = "GET_REPORT_ABUSE_DETAILS_SUCCESS";
export const GET_REPORT_ABUSE_DETAILS_FAILURE = "GET_REPORT_ABUSE_DETAILS_FAILURE";

export const UPDATE_VIDEO_UPLOAD_PROGRESS = "UPDATE_VIDEO_UPLOAD_PROGRESS";

export const UPDATE_INCIDENT_DETAILS_REQUEST = "UPDATE_INCIDENT_DETAILS_REQUEST";
export const UPDATE_INCIDENT_DETAILS_SUCCESS = "UPDATE_INCIDENT_DETAILS_SUCCESS";
export const UPDATE_INCIDENT_DETAILS_FAILURE = "UPDATE_INCIDENT_DETAILS_FAILURE";

export const createIncidentDetails = (data, handleClearForm) => {
  return async (dispatch) => {
    try {
      let uploadPromises;
      // Upload Images
      if (data?.selectedPetImagesFiles?.length > 0) {
        const validImages = data?.selectedPetImagesFiles?.filter((file) => file);
        uploadPromises = validImages?.map(async (file) => {
          try {
            const uploadImage = await axios.post(
              `${BASE_URL}/report/upload/support/evidence/images/v1`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = uploadImage.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            return uploadImage.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        // save the Path
        const supportingEvidance = await Promise.all(uploadPromises);
        if (supportingEvidance) {
          data.supportingEvidance = supportingEvidance;
        }
      }

      // Upload Video
      if (data?.selectedVidioFile && data?.selectedVidioFile !== null) {
        const file = data?.selectedVidioFile;
        // Create a CancelToken source for canceling the request if needed
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        // Upload video with progress tracking
        const uploadVidio = await axios.post(
          `${BASE_URL}/report/upload/vidio/evidence/v1`,
          {
            sFileName: file.name,
            sContentType: file.type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        // Upload video
        const { sUrl, sPath } = uploadVidio?.data?.data;
        await axios.put(sUrl, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            dispatch({ type: "UPDATE_VIDEO_UPLOAD_PROGRESS", payload: percentage });
          },
          cancelToken: source.token,
        });

        data.uploadVideo = sPath;
      }

      dispatch({ type: CREATE_INCIDENT_DETAILS_REQUEST });
      const url = `${BASE_URL}/report/abuse/details/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      dispatch({ type: CREATE_INCIDENT_DETAILS_SUCCESS, payload: response.data });
      localStorage.setItem("formTimestamp", new Date().getTime()); // -- Save current step to localStorage
      handleClearForm();
      toast.success("Incident details save successfully.");
    } catch (error) {
      console.error(error);

      dispatch({ type: CREATE_INCIDENT_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const updateIncidentDetails = (data, handleClearForm) => {
  return async (dispatch) => {
    try {
      let uploadPromises;
      // Upload Images

      if (data?.selectedPetImagesFiles?.length > 0) {
        const validImages = data?.selectedPetImagesFiles?.filter((file) => file);
        uploadPromises = validImages?.map(async (file) => {
          try {
            const uploadImage = await axios.post(
              `${BASE_URL}/report/upload/support/evidence/images/v1`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = uploadImage.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            return uploadImage.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        // save the Path
        const supportingEvidance = await Promise.all(uploadPromises);
        if (supportingEvidance) {
          data.supportingEvidance = supportingEvidance;
        }
      }
      // Upload Video
      if (data?.selectedVidioFile) {
        const file = data.selectedVidioFile;

        // Create a CancelToken source for canceling the request if needed
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        // Upload video with progress tracking
        const uploadVidio = await axios.post(
          `${BASE_URL}/report/upload/vidio/evidence/v1`,
          {
            sFileName: file.name,
            sContentType: file.type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        // Upload video
        const { sUrl, sPath } = uploadVidio?.data?.data;
        await axios.put(sUrl, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            dispatch({ type: "UPDATE_VIDEO_UPLOAD_PROGRESS", payload: percentage });
          },
          cancelToken: source.token,
        });

        data.uploadVideo = sPath;
      }

      const id = JSON.parse(localStorage.getItem("abuseId"));
      dispatch({ type: UPDATE_INCIDENT_DETAILS_REQUEST });
      const url = `${BASE_URL}/report/update/abuse/details/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      dispatch({ type: UPDATE_INCIDENT_DETAILS_SUCCESS, payload: response.data });
      handleClearForm();
      toast.success(response?.data?.message);
    } catch (error) {
      console.error(error);

      dispatch({ type: UPDATE_INCIDENT_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const createPerpetratorDetails = (data, handleClearForm) => {
  return async (dispatch) => {
    try {
      let uploadPromises;
      // Upload Images
      if (data?.selectedPetImagesFiles.length > 0) {
        const validImages = data?.selectedPetImagesFiles?.filter((file) => file);
        uploadPromises = validImages?.map(async (file) => {
          try {
            const uploadImage = await axios.post(
              `${BASE_URL}/report/upload/support/evidence/images/v1`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = uploadImage.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            return uploadImage.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        // save the Path
        const supportingEvidance = await Promise.all(uploadPromises);
        if (supportingEvidance) {
          data.supportingEvidance = supportingEvidance;
        }
      }
      dispatch({ type: CREATE_PERPRATOR_DETAILS_REQUEST });
      const url = `${BASE_URL}/report/perpetrator/details/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_PERPRATOR_DETAILS_SUCCESS, payload: response.data });
      handleClearForm();
      toast.success(response?.data?.message);
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_PERPRATOR_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const createWitnessDetails = (data, handleClearForm,navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_WITNESS_DETAILS_REQUEST });
      const url = `${BASE_URL}/report/witness/details/v1`;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      
      localStorage.removeItem("abuseId");
      localStorage.removeItem("perpetratorId");
      localStorage.removeItem("formTimestamp");

      dispatch({ type: CREATE_WITNESS_DETAILS_SUCCESS, payload: response.data });
      handleClearForm();
      toast.success("Your report details is submitted");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_WITNESS_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const getAllAbuseTypes = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_ABUSE_TYPES_REQUEST });
      const url = `${BASE_URL}/report/get/all/animal/abuse/name/v1`;
      const response = await axios.get(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_ALL_ABUSE_TYPES_SUCCESS, payload: response.data });
      // toast.success("");
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_ALL_ABUSE_TYPES_FAILURE, payload: error.message });
    }
  };
};

export const getReportAbuseDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_REPORT_ABUSE_DETAILS_REQUEST });
      const url = `${BASE_URL}/report/get/single/report/details/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_REPORT_ABUSE_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      // console.error(error);
      toast.success(error?.message);
      dispatch({ type: GET_REPORT_ABUSE_DETAILS_FAILURE, payload: error.message });
    }
  };
};
